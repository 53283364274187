
	import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import ThumbnailOnlyLoader from '@pixcap/ui-core/components/content-loaders/ThumbnailOnlyLoader.vue';

	import LibraryListHeaderLoader from '@pixcap/ui-core/components/content-loaders/LibraryListHeaderLoader.vue';
	import {
		IAssetsLibraryState,
		LIBRARY_TAB,
		NAMESPACE as LIBRARY_NAMESPACE,
		PixcapLibraryPack,
		SEARCH_ROUTES,
	} from '@/models/store/pixcapassets.interface';
	import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
	import { actionsWrapper as LibraryActions } from '@/store/pixcapassets/wrapper';
	import PixcapLibraryItem from '@/components/pixcap-library/shared/PixcapLibraryItem.vue';
	import PackRecommender from '@/components/pixcap-library/library-packs/PackRecommender.vue';
	import { setupBaseMetaTags } from '@/utils/seo';
	import { capitaliseString } from '@/utils/StringUtils';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import {
		Getters as UserGetters,
		GetterTypes as UserGettersTypes,
		GetterTypes as UserGetterTypes,
		NAMESPACE as USER_NAMESPACE,
		SUBSCRIPTION_TYPE,
		SUBSCRIPTION_UPGRADE_CASE,
		UPGRADE_PLAN_TRIGGER_TYPE,
		UserState,
	} from '@pixcap/ui-core/models/store/user.interface';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import { AppState, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import ExploreItemTypeBanner from '@/components/banners/ExploreItemTypeBanner.vue';
	import IconCrown from '@pixcap/ui-library/components/Icons/IconCrown.vue';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';

	@Component({
		name: 'LibraryPackDetails',
		components: {
			IconCrown,
			ExploreItemTypeBanner,
			IconCheck,
			IconLargeArrowRight,
			Button,
			CaptionText,
			HeadingText,
			ThumbnailOnlyLoader,
			BodyText,
			LibraryListHeaderLoader,
			PixcapLibraryItem,
			PackRecommender,
		},
		computed: {
			...mapState(LIBRARY_NAMESPACE, {
				isFetchingPackDetails: (state: IAssetsLibraryState) => state.isFetchingPackDetails,
				packDetailsState: (state: IAssetsLibraryState) => state.packDetailsState,
				isFetchingRelatedPacks: (state: IAssetsLibraryState) => state.isFetchingRelatedPacks,
				relatedPacks: (state: IAssetsLibraryState) => state.relatedPacks,
			}),
			...mapState(USER_NAMESPACE, {
				personalData: (state: UserState) => state.personalData,
			}),
			...mapGetters(USER_NAMESPACE, {
				isProOrAbove: UserGetterTypes.IS_PRO_OR_ABOVE,
				isProUser: UserGettersTypes.IS_PRO,
				isEliteUser: UserGettersTypes.IS_ELITE,
			}),
			...mapState(APP_NAMESPACE, {
				locale: (state: AppState) => state.locale,
			}),
		},
	})
	export default class LibraryPackDetails extends Vue {
		@Prop() packSlug: string;

		personalData: UserState['personalData'];
		isProOrAbove: ReturnType<UserGetters[UserGetterTypes.IS_PRO_OR_ABOVE]>;
		isProUser: ReturnType<UserGetters[UserGettersTypes.IS_PRO]>;
		isEliteUser: ReturnType<UserGetters[UserGettersTypes.IS_ELITE]>;

		isFetchingPackDetails: IAssetsLibraryState['isFetchingPackDetails'];
		packDetailsState: IAssetsLibraryState['packDetailsState'];

		isFetchingRelatedPacks: IAssetsLibraryState['isFetchingRelatedPacks'];
		relatedPacks: IAssetsLibraryState['relatedPacks'];

		locale: AppState['locale'];

		isShowAllTags = false;
		showAllBrands = false;
		LIBRARY_TAB = LIBRARY_TAB;
		SEARCH_ROUTES = SEARCH_ROUTES;

		@Inject() handleScrollBack: () => void;

		get tags() {
			return this.packState.tags || [];
		}

		get hasAnimatedItem() {
			return this.packItems.some((item) => item.isAnimated);
		}

		get author() {
			return 'Pixcap team';
		}

		get isCharacterPack() {
			return this.packItems.every((item) => item.resourceType?.toLowerCase() === LIBRARY_RESOURCE_TYPE.CHARACTERS);
		}

		get packDescription() {
			return this.isCharacterPack
				? this.$t('page.pack_details.character_pack_description', { pack_name: this.packName })
				: this.$t('page.pack_details.pack_description', { pack_name: this.packName });
		}

		get footerType() {
			if (this.packDetailsState?.isBrandKit) return LIBRARY_TAB.MOCKUPS;
			if (this.isCharacterPack) return LIBRARY_TAB.THREE_D_CHARACTERS;
			return LIBRARY_TAB.THREE_D_PACKS;
		}

		get packCreatedDate() {
			const timestamp = Date.parse(this.packDetailsState?.createdAt);
			return new Date(timestamp).toLocaleDateString(this.locale ?? 'en-US', { dateStyle: 'medium' });
		}

		get isOwned() {
			if (this.isProOrAbove) return true;
			const itemIds = this.packItems.map((item) => item.itemId);
			return itemIds.every((id) => this.personalData?.user?.purchaseHistory?.includes(id));
		}

		get packState() {
			return this.packDetailsState || ({} as PixcapLibraryPack);
		}

		get packName() {
			return this.packState.packName;
		}

		get thumbnails() {
			return this.packState.thumbnails;
		}

		get isPro() {
			return this.packState.isPro;
		}

		get packItems() {
			return this.packState.packItems || [];
		}

		get listOfItems() {
			return this.packItems;
		}

		get packSize() {
			return this.packItems.length;
		}

		get packId() {
			return this.packState.packId;
		}

		get relatedQuery() {
			return this.packState?.relatedQuery;
		}

		get relatedPackList() {
			return this.relatedPacks.filter((pack) => pack.packId !== this.packId);
		}

		get thumbnailAltTag() {
			const altTag = `${this.packState?.packSize} ${this.packState?.packName} 3d models`;
			return altTag;
		}

		@Watch('relatedQuery')
		onRelatedQueryChange(next, prev) {
			if (next && next != prev) {
				this.fetchRelatedAssets();
			}
		}

		@Watch('packSlug')
		onPackSlugChange() {
			this.fetchPackDetails();
		}

		handleOpenUpgradeModal() {
			if (this.isProUser) {
				UserMutations.setUpgradeModalState(this.$store, {
					triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
					triggerType: UPGRADE_PLAN_TRIGGER_TYPE.PRO_GET_MORE_LIMITS,
					initPlan: SUBSCRIPTION_TYPE.ELITE,
				});
			} else {
				UserMutations.setUpgradeModalState(this.$store, {
					triggerCase: SUBSCRIPTION_UPGRADE_CASE.USE_PRO_ITEM,
				});
			}
		}

		async fetch() {
			await this.fetchPackDetails();
			if (this.relatedQuery) {
				await this.fetchRelatedAssets();
			}
		}

		head() {
			const headMeta: any = {
				meta: [],
			};
			const metaTagsPaylod: any = {
				url: `${process.env.ROOT_URL}${this.$route.fullPath}`,
			};
			if (this.packDetailsState) {
				const packKeyWord = capitaliseString(`${this.packDetailsState.packName}`);
				metaTagsPaylod.title = this.$t('seo_meta.title_pack_detail', { packKeyWord, packSize: this.packSize });
				metaTagsPaylod.description = this.$t('seo_meta.description_pack_detail', { packKeyWord });
			}
			setupBaseMetaTags(metaTagsPaylod, headMeta, this.$route);
			return headMeta;
		}

		async fetchRelatedAssets() {
			await LibraryActions.fetchRelatedPacks(this.$store, { search: this.packName, isCharacterPack: this.isCharacterPack });
		}

		async fetchPackDetails() {
			if (process.client) {
				this.$nextTick(() => {
					this.handleScrollBack();
				});
			}
			await LibraryActions.fetchLibraryPackDetails(this.$store, this.packSlug);
			if (!this.packDetailsState) {
				this.$nuxt.error({
					message: 'Page not found',
					statusCode: 404,
				});
			}
		}
	}
